.loginicon {
  width: 80px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 40px;
  background:#3f51b5;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 15px;
}

.loginicon:hover{
  background-color: #5264cc;
}

.login-show {
  display: block;
  position: absolute;
  transition: 800ms;
  opacity: 1;
  width: 100%;
  z-index: 100;
}

.login-show.login-active {
  transform: translateY(100%) translateX(100%) scale(0);
  opacity: 0;
}

   .login-box{
  border: 1px solid #fff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  margin: 5px;
  font-size: 11pt;
  background: transparent;
  transition: 1s;
  outline: none;
}

.login-btn{
  width: 75%;
  justify-content: center;
  align-items: center;
  background-color: #3f51b5;
  color: white;
  display: block;
  margin: auto;
  margin-top: 5px;
  border-radius: 10px;
  border: none;
  font-size: 15pt;
  transition: 1s;
  outline: none;
  cursor: pointer;
  margin-bottom: 1.5rem;
  padding: 8px 0;
}

.login-btn:hover{
  background-color: #5264cc;
}

.login-form{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin-top: 60vh;
}

.form-box{
  opacity:1;
  background: black;
  border-radius: 10px;
  box-shadow: 10px 10px 10px 1px rgba(0, 0, 0, 0.2);
  padding: 2rem 2rem;
}

.login-text {
  text-align: center;
  color: #fff;
  margin-bottom: 0.75rem;
}

.close-btn {
  display: block;
  margin-left: auto;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
}

.err-msg {
  color: red;
  font-size: 12pt;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0.75rem;
}

.form-text {
  color: #fff;
  font-size: 12pt;
  text-align: center;
  margin-bottom: 0;
}

.link {
  color: #5264cc;
  font-weight: 700;
  cursor: pointer;
}

.link:hover {
  color: #3f51b5;
}

.login-show label {
  color: #fff;
  font-size: 12pt;
  text-align: center;
}

.signup-group {
  display: flex;
  flex-direction: row;
  column-gap: 1rem
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  
}

.user-details {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  margin-right: 25px;
  border: 1px solid #fff;
  padding: 5px;
  border-radius: 10px;
}

.user-initials {
  background-color: #0d6efd;
  padding: 0.5rem;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-menu {
  display: none;
  position: absolute;
  float: right;
  list-style: none; 
  margin: 0;
  text-align: right;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  background-color: #f9f9f9;
  min-width: 80%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.user-menu a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: center;
}

.user-menu a:hover {background-color: #f1f1f1}

.user-details:hover + .user-menu {
  display: block !important;
}

.user-menu:hover {
  display: block;
  background-color: #3f51b5;
}

.save-btn {
  cursor: pointer;
  display: inline-block;
  margin-left: 0.5rem;
  padding: 0.25rem;
  border-radius: 100%;
}

.save-btn:hover {
  transform: scale(1.2);
  background-color:  	#202020;
}

.replace-btn {
  margin-left: auto;
  margin-right: 10px;
}

.find-btn {
  margin-left: auto;
}

.mr-10 {
  margin-right: 10px;
}

.video-links {
  font-size: 16px;
  margin: 30px 0;
}

.video-links-heading {
  font-size: 18px;
  font-weight: 500;
}

.video-links-heading svg {
  vertical-align: baseline;
}

.video-name {
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  color: #202020;
}

li.video-name:hover {
  text-decoration: underline;
  color: #0d6efd;
}

li.video-name:visited {
  color: #0d6efd;
  text-decoration: underline;
}

::-webkit-scrollbar {
  width: 10px !important;
}